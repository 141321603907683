<template>
    <el-dialog title="历史问卷信息" :visible.sync="open" :close-on-click-modal="true" width="1250px">
        <template #default>
            <div class="followBox">

             

                <el-table :default-sort="{prop: 'FillTime', order: 'descending'}" v-loading="fullscreenLoading" border :data="list" height="500" style="width: 100%">
                    <el-table-column align="center" type="index" width="50" label="序号" />
                    <el-table-column align="center" prop="QuestionnaireName" label="问卷名称" width="140">

                    </el-table-column>
                    <el-table-column  prop="FillTime" align="center" label="填写时间" width="140">
                        <template slot-scope="scope">
                            <span>{{ FillTimeTiem(scope.row.FillTime) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="SASvalue" align="center" label="SAS分值">

                    </el-table-column>
                    <el-table-column prop="SASresult" align="center" label="SAS结果">

                    </el-table-column>
                    <el-table-column prop="SDSvalue" align="center" label="SDS分值">

                    </el-table-column>
                    <el-table-column prop="SDSresult" align="center" label="SDS结果">

                    </el-table-column>
                    <el-table-column width="300" show-overflow-tooltip prop="remarks" align="center" label="备注">

                    </el-table-column>
                    <el-table-column width="130" show-overflow-tooltip prop="visitRecommend" align="center" label="操作">
                        <template slot-scope="scope">
                            <el-link @click="lookClick(scope.row)" type="primary">查看问卷</el-link>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </template>
    </el-dialog>
</template>
<script>
export default {
    name: 'Historical-questionnaire',
    props: {
        value: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        list: {
            type: Array,
            default: () => {
                return [];
            }
        },
        fullscreenLoading:{
            type:Boolean,
            default:()=>{
                return false;
            }
        }
    },
    computed: {
        open: {
            get() {
                return this.value
            },
            set(v) {
                console.log(v);
                this.$emit("input", v)
            },
        },
    },
    methods: {
        lookClick(row) {
            console.log(row);
          
            this.$router.push({
                path: '/' + 'haiHeQuestionnaire' + 'Detail',
                query: {
                    reagentId: row.patientOpenid,
                    // state: this.sort[this.routerName],
                    time:row.FillTime
                },
            })
        },
        FillTimeTiem(dateString) {
            const year = dateString.substring(0, 4);
            const month = dateString.substring(4, 6);
            const day = dateString.substring(6, 8);

            // 格式化为 YYYY-MM-DD
            return `${year}/${month}/${day}`;
        }

    }
}
</script>
<style lang="scss" scoped>
@import "@/style/TestKit/TestKitDialog.scss";

.followBox {
    min-height: 310px;
    padding: 20px;

}
</style>